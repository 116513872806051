import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import Illustration from "../../assets/illustration.svg";
import { NavHashLink } from "react-router-hash-link";
import linkedin from '../../assets/linkedin.svg';
// import githubIcon from '../../assets/github.svg';
import whatsapp from '../../assets/whatsapp.svg';
import Hello from '../../assets/Hello.gif';
// import telegram from '../../assets/telegram.svg';

export function Hero() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Adjust the delay as needed

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  return (
    <Container id="home">
      <div className={`hero-text ${isVisible ? "fadeInUp" : ""}`}>
        <p className="flex">
          Hello <img src={Hello} alt="Hello" width="35px" />, I'm
          </p>
        <h1 className={isVisible ? "fadeInUp" : ""}>Salman Khan</h1>
        <h3 className={isVisible ? "fadeInUp" : ""}>Full Stack Developer</h3>
        <p className={`small-resume ${isVisible ? "fadeInUp" : ""}`}>
          8+ Years of Experience
        </p>
        <div className="flex">
          <NavHashLink smooth to="#contact" className={`button ${isVisible ? "fadeInUp" : ""}`}>
            Contact
          </NavHashLink>
          <div>
            <div className={`social-media mt-4 ${isVisible ? "fadeInUp" : ""}`}>
              <a href="https://www.linkedin.com/in/salman-khan-sohoo" target="_blank" rel="noreferrer">
                <img src={linkedin} alt="Linkedin" />
              </a>
              <a href="https://api.whatsapp.com/send/?phone=+971586108809&text=Hello+Salman" target="_blank" rel="noreferrer">
                <img src={whatsapp} alt="Whatsapp" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`hero-image ${isVisible ? "fadeInRight" : ""}`}>
        <img src={Illustration} alt="Ilustração" />
      </div>
    </Container>
  );
}
