// src/api.js
import axios from 'axios';

const BASE_URL = 'https://app.thesalmankhan.com/wp-json/wp/v2';

export const makeRequest = async (url, method = 'GET', data = null) => {
    const allData = []; // Generic name for storing response data
    let page = 1;
    let keepFetching = true;

    try {
        while (keepFetching) {
            const response = await axios({
                method: method,
                url: `${BASE_URL}/${url}?page=${page}`,
                data: method === 'POST' ? data : null, // Send data only for POST requests
            });

            allData.push(...response.data);

            if (response.data.length === 0 || page >= response.headers['x-wp-totalpages']) {
                keepFetching = false;
            }

            page++;
        }

        return allData; // Return all data to be set in state
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error to handle it in the calling function
    }
};

export const makeRequestPaginated = async (page) => {
    try {
        const response = await axios.get(`${BASE_URL}/${page}`);

        // Check the headers for pagination data
        const totalItems = response.headers['x-wp-total'];
        const totalPages = response.headers['x-wp-totalpages'];

        return {
            projects: response.data,
            totalItems,
            totalPages,
        };
    } catch (error) {
        console.error('Error fetching projects:', error);
        throw error; // Rethrow or handle the error as needed
    }
};

export const fetchProjectDetail = async (slug) => {
    const response = await fetch(`${BASE_URL}/projects?slug=${slug}`);
    return await response.json();
};

export const fetchKnowledgesPaginatedData = async (page) => {
    const response = await axios.get(`${BASE_URL}/knowledges?page=${page}`);
    return response;
};

export const fetchDevelopmentSkillsPaginatedData = async (page) => {
    const response = await axios.get(`${BASE_URL}/development_skills?page=${page}`);
    return response;
};

export const fetchCodingSkillsPaginatedData = async (page) => {
    const response = await axios.get(`${BASE_URL}/coding_skills?page=${page}`);
    return response;
};

export const fetchEdicationListPaginatedData = async (page) => {
    const response = await axios.get(`${BASE_URL}/education_list?page=${page}`);
    return response;
};

// export const fetchProjectsPaginatedData = async (page) => {
//     const response = await axios.get(`${BASE_URL}/projects?page=${page}`);
//     return response;
// };

export const fetchExperiencesPaginatedData = async (page) => {
    const response = await axios.get(`${BASE_URL}/experiences?page=${page}`);
    return response;
};
