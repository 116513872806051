import React from 'react';

function Contact() {
    return (
        <div id="contact">
            <h1>Welcome to my Contact!</h1>
            {/* Other content goes here */}
        </div>
    );
}

export default Contact;
