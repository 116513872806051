import React from 'react';
import { useTheme } from '../../ThemeContext';

function Spinner({ message }) {
    const { isLightMode } = useTheme();

    return (
        <div className="flex items-center justify-center min-h-screen"> {/* Full height and center alignment */}
            <div className="flex flex-col items-center">
                <div className={`spinner w-12 h-12 border-4 border-t-transparent border-green-500 rounded-full animate-spin ${isLightMode ? 'text-green-700' : 'text-green-300'}`}></div>
                <p className={`loading-text mt-4 text-lg ${isLightMode ? 'text-green-700' : 'text-green-300'} ml-2`}>Loading {message}...</p>
            </div>
        </div>
    );
}

export default Spinner;
