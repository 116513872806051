import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { GlobalStyle } from './styles/global';
import Home from './pages/Home'; // Adjust the import path
import About from './pages/About'; // Adjust the import path
import Projects from './pages/Projects'; // Adjust the import path
import ProjectDetails from './pages/ProjectDetails/ProjectDetails';
import Portfolio from './pages/Portfolio'; // Adjust the import path
import Contact from './pages/Contact'; // Adjust the import path
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/project/:slug" element={<ProjectDetails />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
