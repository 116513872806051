import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../ThemeContext';

export const Modal = ({ isOpen, onClose, title, children, closeUrl }) => {
    const navigate = useNavigate();
    const { isLightMode } = useTheme();

    const handleClose = () => {
        if (closeUrl) {
            navigate(closeUrl);
        } else if (onClose) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ zIndex: 1050 }}
        >
            <div
                className={`w-full h-full p-6 rounded-lg shadow-lg flex flex-col ${isLightMode ? 'bg-white' : 'bg-gray-800'}`}
                style={{ zIndex: 1051 }}
            >
                <div className={`flex justify-between items-center pb-3 ${isLightMode ? 'border-gray-200' : 'border-gray-600'}`}>
                    <h2 className={`text-4xl font-semibold ${isLightMode ? 'text-gray-800' : 'text-gray-200'}`}>{title}</h2>
                    <button
                        onClick={handleClose}
                        className={`p-0 bg-transparent border-none ${isLightMode ? 'text-gray-400 hover:text-gray-600' : 'text-gray-300 hover:text-gray-500'}`}
                    >
                        <svg
                            height="20px"
                            width="20px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 490 490"
                            fill="none"
                        >
                            <polygon
                                fill={isLightMode ? "#000000" : "#FFFFFF"} // Use black in light mode and white in dark mode
                                points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337"
                            />
                        </svg>
                    </button>
                </div>
                <div className={`flex-grow mt-4 overflow-auto ${isLightMode ? 'text-gray-800' : 'text-gray-300'}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};
