import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Container } from "./styles.js";
import { makeRequestPaginated } from '../../api.js';
import { useTheme } from '../../ThemeContext';
import Spinner from '../../components/Spinner/Spinner';

export function Projects() {
  const [projects, setProjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(null); // Current page state
  const [totalPages, setTotalPages] = useState(1);   // Total pages state

  const { isLightMode } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  // Effect to get the page from the URL on initial mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get('page'), 10); // Read page param from URL
    if (!isNaN(page) && page > 0) {
      setCurrentPage(page); // Set current page from URL param
    } else {
      setCurrentPage(1); // Default to 1 if no valid page param
    }
  }, [location.search]);

  const fetchData = useCallback(async (page) => {
    const perPage = 9;
    try {
      const url = `projects?page=${page}&orderby=menu_order&order=asc&per_page=${perPage}`;
      const response = await makeRequestPaginated(url);

      // Assume the response has a structure like { projects: [...], totalPages: number }
      setProjects(response.projects);  // Set projects from the API response
      setTotalPages(response.totalPages); // Set total pages from API response
      setIsLoaded(true);  // Set loading to false once data is loaded

      // Update URL to include the current page
      navigate(`?page=${page}`, { replace: true }); // Use replace to avoid adding to history
    } catch (err) {
      console.error(err);
      setError(err); // Handle error if API call fails
    }
  }, [navigate]);

  // Effect to fetch data whenever currentPage changes
  useEffect(() => {
    if (currentPage !== null) {
      fetchData(currentPage);
    }
  }, [currentPage, fetchData]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Show spinner while loading is true
  if (!isLoaded) {
    return <Spinner message="Projects" />;
  }

  // Handle any errors that occurred while fetching data
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Create an array of page numbers to display in the pagination
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <Container id="projects">
      <h2>Portfolio</h2>
      <div className="projects">
        {projects.map((project, index) => {
          const { slug, title, content, acf, featured_media_url } = project;
          const technologies = acf.technologies;

          return (
            <div
              className={`project ${isLightMode
                ? 'bg-white hover:bg-customGray hover:text-white'
                : 'bg-customGray hover:bg-white hover:text-gray-900'}`}
              key={index}
            >
              <div>
                <div className="body">
                  <Link to={`/project/${slug}`} rel="noreferrer">
                    <figure className="relative transition-all duration-300 w-full cursor-pointer overflow-hidden">
                      <img src={featured_media_url} alt="Visit site" className="w-full h-80 object-cover object-top" />
                      <figcaption className="absolute px-4 text-lg bottom-6">
                        <p>{content.rendered}</p>
                      </figcaption>
                    </figure>
                  </Link>
                  <h3 className="inline-flex items-center text-base font-semibold mr-5 mb-5 transition-colors duration-300"  dangerouslySetInnerHTML={{ __html: title.rendered }} />
                </div>
                <footer>
                  {technologies.map((tech, techIndex) => (
                    <span
                      className={`inline-flex items-center rounded-md px-5 py-2.5 text-base font-semibold ring-2 ring-inset mr-5 mb-5 transition-colors duration-300 ${isLightMode
                        ? 'bg-green-50 text-green-700 ring-green-600/20 hover:bg-green-600 hover:text-white'
                        : 'bg-green-900 text-green-300 ring-green-500 hover:bg-green-500 hover:text-black'
                        }`}
                      key={techIndex}
                    >
                      {tech}
                    </span>
                  ))}
                </footer>
              </div>
            </div>
          );
        })}
      </div>

      {/* Pagination Controls */}
      <div className="pagination mt-5 flex justify-center items-center">
        <button
          className={`px-4 py-2 mr-2 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'bg-green-500 text-white'}`}
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {/* Render Page Numbers */}
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={`px-4 py-2 mx-1 rounded ${currentPage === pageNumber ? 'bg-green-700 text-white' : 'bg-gray-200 text-black'}`}
            onClick={() => handlePageClick(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
        <button
          className={`px-4 py-2 ml-2 rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'bg-green-500 text-white'}`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      <div className="pagination mt-5 flex justify-center items-center">
        <span className="mx-4">Page {currentPage} of {totalPages}</span>
      </div>
    </Container>
  );
}
