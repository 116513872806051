import { Container } from './styles';
import { NavHashLink } from 'react-router-hash-link';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Resume from '../../assets/Salman_Khan_Resume.pdf';
import { useTheme } from '../../ThemeContext';

export function Header() {
  const { isLightMode, toggleTheme } = useTheme();
  const [isActionActive, setActionActive] = useState(false);

  function closeMenu() {
    setActionActive(false);
  }

  return (
    <Container className="header-fixed">
      <NavHashLink smooth to="/#home" className="logo">
        <span>{"<Salman "}</span>
        <span>{" Khan/>"}</span>
      </NavHashLink>

      <input
        onChange={toggleTheme}
        className="container_toggle"
        type="checkbox"
        id="switch"
        name="mode"
        checked={isLightMode} // Use the global theme state
        aria-label="Toggle theme"
      />
      <label htmlFor="switch">Toggle</label>

      <nav className={isActionActive ? 'active' : ''}>
        <NavHashLink smooth to="/#home" onClick={closeMenu}>
          Home
        </NavHashLink>
        <NavHashLink smooth to="/#about" onClick={closeMenu}>
          About me
        </NavHashLink>
        <Link to="/projects" onClick={() => { closeMenu(); window.scrollTo(0, 0); }}>
          Projects
        </Link>
        <NavHashLink smooth to="/#contact" onClick={closeMenu}>
          Contact
        </NavHashLink>
        <a href={Resume} download className="button">
          Resume
        </a>
      </nav>

      <div
        aria-expanded={isActionActive}
        aria-haspopup="true"
        aria-label={isActionActive ? 'Close menu' : 'Open menu'}
        className={`menu ${isActionActive ? 'active' : ''}`}
        onClick={() => setActionActive(prev => !prev)}
      />
    </Container>
  );
}
