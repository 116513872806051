import React from 'react';

function Portfolio() {
    return (
        <div id="portfolio">
            <h1>Welcome to my portfolio!</h1>
            {/* Other content goes here */}
        </div>
    );
}

export default Portfolio;
