import React, { useState, useEffect, useCallback } from 'react';
import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import { makeRequest } from '../../api.js';
import { useTheme } from '../../ThemeContext';

export function Resume() {
  const [knowledges, setKnowledges] = useState([]);
  const [developmentSkills, setDevelopmentSkills] = useState([]);
  const [codingSkills, setCodingSkills] = useState([]);
  const [experiences, setExperiencesSkills] = useState([]);
  // const [isLoaded, setIsLoaded] = useState(false);
  // const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [progressWidths, setProgressWidths] = useState({});
  const { isLightMode } = useTheme();

  const fetchKnowledgeData = useCallback(async () => {
    try {
      const response = await makeRequest('knowledges');
      setKnowledges(response);
    } catch (err) {
      console.error(err);
      // setError('Failed to fetch knowledge data.');
    }
  }, []);

  const fetchDevelopmentSkillsData = useCallback(async (page = 1) => {
    try {
      const response = await makeRequest('development_skills');
      setDevelopmentSkills(response);

      const initialWidths = response.reduce((acc, skill, index) => {
        acc[index] = 0;
        return acc;
      }, {});
      setProgressWidths(initialWidths);

      // setIsLoaded(true);

      setTimeout(() => {
        const updatedWidths = response.reduce((acc, skill, index) => {
          acc[index] = skill.acf.percentage;
          return acc;
        }, {});
        setProgressWidths(updatedWidths);
      }, 200);
    } catch (err) {
      console.error(err);
      // setError(err);
    }
  }, []);

  const fetchCodingSkillsData = useCallback(async (page = 1) => {
    try {
      const response = await makeRequest('coding_skills');
      setCodingSkills(response);

      const initialWidths = response.reduce((acc, skill, index) => {
        acc[index] = 0;
        return acc;
      }, {});
      setProgressWidths(initialWidths);

      // setIsLoaded(true);

      setTimeout(() => {
        const updatedWidths = response.reduce((acc, skill, index) => {
          acc[index] = skill.acf.percentage;
          return acc;
        }, {});
        setProgressWidths(updatedWidths);
      }, 200);
    } catch (err) {
      console.error(err);
      // setError(err);
    }
  }, []);

  const fetchExperiencesData = useCallback(async (page = 1) => {
    try {
      const response = await makeRequest('experiences');
      setExperiencesSkills(response);
      // setIsLoaded(true);
    } catch (err) {
      console.error(err);
      // setError(err);
    }
  }, []); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchKnowledgeData(),
          fetchDevelopmentSkillsData(),
          fetchCodingSkillsData(),
          fetchExperiencesData(),
        ]);
        // setIsLoaded(true);
      } catch (err) {
        console.error(err);
        // setError('Failed to fetch data.');
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [fetchKnowledgeData, fetchDevelopmentSkillsData, fetchCodingSkillsData, fetchExperiencesData]);

  function convertStringToDate(dateString) {
    // Validate the input format (must be a string of length 8)
    if (typeof dateString !== 'string' || dateString.length !== 8) {
      throw new Error('Invalid date string format. Expected format: YYYYMMDD');
    }

    // Extract year, month, and day from the string
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are zero-indexed
    const day = parseInt(dateString.substring(6, 8), 10);

    // Create a new Date object
    const date = new Date(year, month, day);

    // Format the date as "Month Year" with abbreviated month
    const options = { year: 'numeric', month: 'short' }; // Changed 'long' to 'short'
    return date.toLocaleDateString(undefined, options); // Returns formatted date
  }

  return (
    <Container id="resume-section">
      <div className="flex flex-col md:flex-row max-w-full mx-auto p-0 md:p-6">
        {/* Timeline Section */}
        <div className="w-full md:w-8/12 md:pr-4 order-2 md:order-1">
          <ScrollAnimation animateIn="fadeInUp">
            <div className="container mx-auto py-10">
              <h2 className="text-3xl font-bold mb-0 md:mb-8">Experience</h2>
              <div className="relative font-inter antialiased">
                <main className="relative min-h-screen flex flex-col overflow-hidden">
                  <div className="w-full max-w-12xl p-0 md:px-6">
                    <div className="flex flex-col justify-center divide-y divide-slate-200 [&>*]:py-16">
                      <div className={`w-full mx-auto py-10 max-w-12xl rounded-lg md:px-6`}>
                        <div className="-my-6">
                          {experiences.map((experience, index) => {
                            // Store the converted date into a variable
                            const fromDate = convertStringToDate(experience.acf.from);
                            // Convert the to date with a check for emptiness
                            const toDateString = experience.acf.to;
                            const toDate = toDateString ? convertStringToDate(toDateString) : null;
                            // Format the dates for display
                            const formattedFromDate = fromDate.toLocaleString('default', { month: 'long', year: 'numeric' });
                            const formattedToDate = toDate ? toDate.toLocaleString('default', { month: 'long', year: 'numeric' }) : 'Present';
                            // Determine the date display based on currently_working
                            const dateDisplay = experience.acf.currently_working
                              ? formattedFromDate
                              : `${formattedFromDate} - ${formattedToDate}`;

                            return (
                              <ScrollAnimation key={experience.id} animateIn="fadeInUp" delay={index * 100} animateOnce={true}>
                                <div key={experience.id} className={`relative md:pl-[22rem] md:py-6 group ${isLightMode ? 'bg-white md:bg-transparent my-4 py-0 md:py-6 md:my-0 rounded-lg' : 'bg-white md:bg-transparent my-4 py-0 md:my-0 rounded-lg'}`}>
                                  <div className="flex flex-col sm:flex-row items-center md:items-start pt-2 mb-1 before:content-[''] before:absolute before:left-2 before:h-full before:px-px before:bg-slate-300 before:self-start before:-translate-x-1/2 before:translate-y-3 before:hidden sm:before:block sm:before:ml-[19rem] after:content-[''] after:absolute after:left-2 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full after:hidden sm:after:block sm:after:ml-[19rem] after:-translate-x-1/2 after:translate-y-1.5">
                                    {experience.acf.currently_working && (
                                      <span className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center font-semibold uppercase h-10 mb-3 sm:mb-0 text-green-700 p-2 text-xl text-red-600 ml-2 sm:mb-4">(Currently Working)</span>
                                    )}
                                    <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xl font-semibold capital h-10 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded p-2">
                                      {dateDisplay}
                                    </time>
                                    <div
                                      style={{ top: '4.5rem', width: '20rem' }}
                                      className={`sm:absolute left-0 translate-y-0.5 inline-flex font-semibold capital h-10 mb-3 sm:mb-0 text-xl font-bold ${isLightMode ? 'text-black' : 'text-black md:text-white'}`}
                                    >
                                      {experience.title.rendered}
                                    </div>
                                  </div>
                                  <div className="text-white-500">
                                    <div className={`font-caveat font-medium text-2xl ${isLightMode ? 'text-black' : 'text-black md:text-white'} mb-1 pb-2 sm:mb-0`}>
                                      {experience.acf.company}
                                    </div>
                                    <div className="bg-white shadow-md rounded-lg max-w-md p-4 ml-6 md:ml-0">
                                      <div className="flex items-center justify-center">
                                        <img
                                          src={experience.featured_media_url}
                                          alt={`${experience.acf.company} logo`}
                                          className="h-20 mr-2"
                                        />
                                      </div>
                                    </div>
                                    <div className={`mt-4 p-4 rounded-lg shadow-md ${isLightMode ? 'bg-white text-black hover:bg-green-700 hover:text-white' : 'bg-white text-black hover:bg-green-700 hover:text-white'}`} dangerouslySetInnerHTML={{ __html: experience.content.rendered }} />
                                  </div>
                                </div>
                              </ScrollAnimation>
                            );
                          })}
                          {/* Extra empty div for the final dot */}
                          <div className="relative pl-8 sm:pl-44 py-6 group">
                            <div className="flex flex-col sm:flex-row items-start mb-1 before:absolute before:left-2 sm:before:left-0 before:px-px before:bg-slate-300 sm:before:ml-[19rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[19rem] after:-translate-x-1/2 after:translate-y-1.5">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </ScrollAnimation>
        </div>
        
        {/* Additional Information Section */}
        <div className="w-full md:w-4/12 md:pl-4 order-1 md:order-2">
          <ScrollAnimation animateIn="fadeInRight">
            <h2 className="text-3xl font-bold mb-4">Development Skills</h2>
            <div className="text-gray-700">
              {developmentSkills
                .sort((a, b) => {
                  // First, sort by percentage (highest first)
                  const percentageDiff = b.acf.percentage - a.acf.percentage;
                  if (percentageDiff !== 0) {
                    return percentageDiff; // If percentages are different, use this sort order
                  }

                  // If percentages are the same, sort alphabetically by title
                  return a.title.rendered.localeCompare(b.title.rendered);
                })
                .map((skill, index) => {
                const { title, acf } = skill;
                const percentage = acf.percentage;

                return (
                  <div key={index} className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <span className={`inline-flex rounded-md px-5 py-2.5 text-base font-semibold ring-inset ${isLightMode ? 'text-green-700 hover:bg-green-600 hover:text-white' : 'text-green-300 hover:bg-green-500 hover:text-black'}`}>
                        {title.rendered}
                      </span>
                      <span className={`text-sm font-bold ${isLightMode ? 'text-green-700 hover:text-white' : 'text-green-300 hover:text-black'}`}>
                        {percentage}%
                      </span>
                    </div>

                    <div className="w-full bg-gray-300 rounded-full h-4">
                      <div
                        className="bg-green-500 h-4 rounded-full transition-all duration-1000 ease-out"
                        style={{ width: `${progressWidths[index]}%` }} // Use the calculated width for animation
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeInRight" className="mt-12">
            <h2 className="text-3xl font-bold mb-4">Coding Skills</h2>
            <div className="text-gray-700">
              {codingSkills
                .sort((a, b) => {
                  // First, sort by percentage (highest first)
                  const percentageDiff = b.acf.percentage - a.acf.percentage;
                  if (percentageDiff !== 0) {
                    return percentageDiff; // If percentages are different, use this sort order
                  }

                  // If percentages are the same, sort alphabetically by title
                  return a.title.rendered.localeCompare(b.title.rendered);
                })
                .map((skill, index) => {
                  const { title, acf } = skill;
                  const percentage = acf.percentage;
                return (
                  <div key={index} className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <span className={`inline-flex rounded-md px-5 py-2.5 text-base font-semibold ring-inset ${isLightMode ? 'text-green-700 hover:bg-green-600 hover:text-white' : 'text-green-300 hover:bg-green-500 hover:text-black'}`}>
                        {title.rendered}
                      </span>
                      <span className={`text-sm font-bold ${isLightMode ? 'text-green-700 hover:text-white' : 'text-green-300 hover:text-black'}`}>
                        {percentage}%
                      </span>
                    </div>

                    <div className="w-full bg-gray-300 rounded-full h-4">
                      <div
                        className="bg-green-500 h-4 rounded-full transition-all duration-1000 ease-out"
                        style={{ width: `${progressWidths[index]}%` }} // Use the calculated width for animation
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeInRight" className='mt-12'>
            <h2 className="text-3xl font-bold mb-4">Knowledges</h2>
            <div className="text-gray-700">
              {knowledges.map((knowledge, index) => {
                const { title } = knowledge;

                return (
                  <span
                    key={index}
                    className={`inline-flex items-center rounded-md px-5 py-2.5 text-base font-semibold ring-2 ring-inset mr-5 mb-5 ${isLightMode
                      ? "bg-green-50 text-green-700 ring-green-600/20 hover:bg-green-600 hover:text-white"
                      : "bg-green-900 text-green-300 ring-green-500 hover:bg-green-500 hover:text-black"
                      }`}
                  >
                    {title.rendered}
                  </span>
                );
              })}
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </Container>
  );
}
