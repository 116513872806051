// NotFound.js
import React from 'react';
// import { Container } from './styles';
import { useTheme } from '../../ThemeContext';

const NotFound = ({ page, location }) => {
    const { isLightMode } = useTheme();

    return (
        <div className={`flex items-center justify-center min-h-screen ${isLightMode ? 'bg-white' : 'bg-dark'}`}>
            <div className="text-center">
                <h1 className="text-6xl font-bold text-red-600">404</h1>
                <h2 className={`mt-4 text-2xl font-semibold ${isLightMode ? 'text-gray-800' : 'text-white'}`}>
                    {page} Not Found
                </h2>
                <p className={`mt-2 ${isLightMode ? 'text-gray-600' : 'text-gray-400'}`}>
                    Sorry, the page you're looking for does not exist.
                </p>
                <a
                    href={`${location}`}
                    className="mt-6 inline-block px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                    Go to Home
                </a>
            </div>
        </div>
    );
};

export default NotFound;
