import React from 'react';
import { Projects as ProjectsComponent } from "../components/Projects/Projects";

function Projects() {
    return (
        <div id="projects" className='my-8'>
            <ProjectsComponent />
        </div>
    );
}

export default Projects;
