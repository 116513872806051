import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from "./styles";
import { fetchProjectDetail } from '../../api';
import NotFound from '../../components/NotFound/NotFound';
import { Modal } from '../../components/Modal/Modal';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useTheme } from '../../ThemeContext';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaDigg } from 'react-icons/fa';

function ProjectDetails() {
    const { slug } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0); // Add state for current index

    const closeModal = () => setModalOpen(false);
    const { isLightMode } = useTheme();

    const projectSlug = project ? project.slug : '';
    const baseUrl = `http://www.my-portfolio.local/project/${projectSlug}`;

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await fetchProjectDetail(slug);
                if (response && response.length > 0) {
                    setProject(response[0]);
                } else {
                    setProject(null);
                }
            } catch (error) {
                console.error('Error fetching project details:', error);
                setProject(null);
            } finally {
                setLoading(false);
            }
        };
        fetchProject();
    }, [slug]);

    if (loading) {
        return (
            <Container>
                <div className="loader-container">
                    <div className={`spinner text-green-700`}></div>
                    <p className="loading-text text-green-700 ml-2">Loading project details...</p>
                </div>
            </Container>
        );
    }

    if (!project) {
        return <NotFound page="Project Details" location="/projects" />;
    }

    const photoGallery = project.acf?.photo_gallery?.photo_gallery[0];

    // Map photoGallery to the format expected by the Lightbox
    const slides = photoGallery?.map(image => ({
        src: image.full_image_url,
        // Optionally add a caption if needed
        // caption: image.caption || ''
    })) || [];
    
    function decodeHtmlEntities(text) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            title={decodeHtmlEntities(project.title?.rendered) || 'No Title'}
            closeUrl="/projects"
        >
            <div className="flex">
                <div className="pr-4" style={{ width: '70%' }}>
                    <h2 className="text-3xl mb-4">Project Gallery</h2>
                    {photoGallery && (
                        <div className="photo-gallery grid grid-cols-3 gap-2">
                            {photoGallery.map((image, index) => (
                                <div
                                    key={index}
                                    className="overflow-hidden border border-gray-200 rounded-lg flex flex-col justify-start"
                                    onClick={() => {
                                        setCurrentIndex(index); // Set the current index
                                        setOpen(true); // Open the lightbox
                                    }}
                                >
                                    <img
                                        src={image.full_image_url}
                                        alt={`${image.title} ${index + 1}`}
                                        className="w-full h-80 object-cover object-top"
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="pl-4" style={{ width: '30%' }}>
                    <h1 className="text-2xl font-bold mb-4">{project.title?.rendered || 'No Title'}</h1>
                    {project.content?.rendered ? (
                        <div dangerouslySetInnerHTML={{ __html: project.content.rendered }} />
                    ) : (
                        <p></p>
                    )}

                    {/* Render Client information */}
                    {project.acf.client && (
                        <div className="mb-4">
                            <p className="font-semibold"></p>
                            <p>👤 {project.acf.client}</p>
                        </div>
                    )}

                    {/* Render Website information */}
                    {project.acf.site_url && (
                        <div className="mb-4">
                            <span className="text-blue-500 mr-2">🌐</span>
                            <a href={project.acf.site_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                {project.acf.site_url}
                            </a>
                        </div>
                    )}

                    {/* Render additional site URLs if they exist */}
                    {project.acf.site_url_text_2 && project.acf.site_url_2 && (
                        <div className="mb-4">
                            <span className="text-blue-500 mr-2">🌐</span>
                            <a href={project.acf.site_url_2} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                {project.acf.site_url_2}
                            </a>
                        </div>
                    )}

                    {project.acf.site_url_text_3 && project.acf.site_url_3 && (
                        <div className="mb-4">
                            <p className="font-semibold">{project.acf.site_url_text_3}:</p>
                            <a href={project.acf.site_url_3} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                {project.acf.site_url_3}
                            </a>
                        </div>
                    )}

                    {/* Render Technologies if they exist */}
                    {project.acf.technologies && project.acf.technologies.length > 0 && (
                        <div className="mb-4">
                            <p className="font-semibold">Technologies:</p>
                            {project.acf.technologies.map((tech, index) => (
                                <span className={`inline-flex items-center rounded-md px-5 py-2.5 text-base font-semibold ring-2 ring-inset mr-5 mb-5 transition-colors duration-300 ${isLightMode
                                    ? 'bg-green-50 text-green-700 ring-green-600/20 hover:bg-green-600 hover:text-white'
                                    : 'bg-green-900 text-green-300 ring-green-500 hover:bg-green-500 hover:text-black'
                                    }`} key={index}>{tech}</span>
                            ))}
                        </div>
                    )}

                    {/* Share Section */}
                    <h2 className="text-lg font-bold text-gray-800 mt-4 mb-2">Share</h2>
                    <div className="flex space-x-4 p-4">
                        <div className="relative group">
                            <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(baseUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-center text-white bg-blue-600 hover:bg-blue-700 p-4 rounded-full transition duration-200"
                            >
                                <FaFacebookF />
                            </a>
                            <span className="absolute bottom-12 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-sm rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 min-w-max whitespace-nowrap">
                                Share on Facebook
                            </span>
                        </div>

                        <div className="relative group">
                            <a
                                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(baseUrl)}&text=${encodeURIComponent(project.title?.rendered || 'Check this out!')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-center text-white bg-blue-400 hover:bg-blue-500 p-4 rounded-full transition duration-200"
                            >
                                <FaTwitter />
                            </a>
                            <span className="absolute bottom-12 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-sm rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 min-w-max whitespace-nowrap">
                                Share on Twitter
                            </span>
                        </div>

                        <div className="relative group">
                            <a
                                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(baseUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 p-4 rounded-full transition duration-200"
                            >
                                <FaLinkedinIn />
                            </a>
                            <span className="absolute bottom-12 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-sm rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 min-w-max whitespace-nowrap">
                                Share on LinkedIn
                            </span>
                        </div>

                        <div className="relative group">
                            <a
                                href={`https://www.digg.com/submit?url=${encodeURIComponent(baseUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-center text-white bg-orange-600 hover:bg-orange-700 p-4 rounded-full transition duration-200"
                            >
                                <FaDigg />
                            </a>
                            <span className="absolute bottom-12 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-sm rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 min-w-max whitespace-nowrap">
                                Share on Digg
                            </span>
                        </div>
                    </div>

                </div>

            </div>

            {/* Lightbox */}
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
                index={currentIndex} // Pass the current index
            />
        </Modal>
    );
}

export default ProjectDetails;
