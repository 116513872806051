import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.section`
  margin-top: 15rem;

  .project {
    margin-top: 15rem;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  .spinner {
    border: 4px solid rgb(21 128 61 / var(--tw-text-opacity));
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left-color: #ffffff;
    animation: ${spin} 1s ease infinite;
  }

  .loading-text {
    margin-top: 10px;
    font-size: 1.2rem;
  }
`;
