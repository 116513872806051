import React from 'react';

function About() {
    return (
        <div id="about">
            <h1>Welcome to my about!</h1>
            {/* Other content goes here */}
        </div>
    );
}

export default About;
