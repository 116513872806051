import React from 'react';
import { Main } from '../components/Main/Main';

function Home() {
    return (
        <div id="home">
            <Main></Main>
        </div>
    );
}

export default Home;
